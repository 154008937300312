<template>
  <div class="item-page">
    <div class="item-page__header" v-if="loading">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__body" v-if="loading">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          width="50%"
          height="100%"
      />
      <v-skeleton-loader
          type="list-item-avatar, list-item-avatar"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__header" v-if="!loading">
      <img :src="require('@/assets/img/pages/bill.png')" alt="" class="mr-9">
      <div class="item-page__name">
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="bill.number"
            disabled
        >
          <template v-slot:label>
            Номер счёта
          </template>
        </v-text-field>
        <div class="item-page__actions  mt-1">
          <v-btn text class="evi-button-text" @click="openUrlNewTab">
            <img class="mr-2" :src="require('@/assets/img/common/newtab.svg')" alt="">
            Открыть счёт
          </v-btn>
          <v-btn text class="evi-button-text" @click="copyUrl">
            <img class="mr-2" :src="require('@/assets/img/common/copy.svg')" alt="">
            Копировать ссылку
          </v-btn>
          <v-btn text class="evi-button-text" v-if="hasRight('bills.manage')" @click="removeModal = true">
            <img class="mr-2" :src="require('@/assets/img/users/red.svg')" alt="">
            Удалить
          </v-btn>
        </div>
      </div>
      <div class="item-page__close" @click="$router.push({path: '/bills'})">
        <v-icon size="20">mdi-close</v-icon>
      </div>
    </div>
    <div class="item-page__body" v-if="!loading">
      <div class="item-page__edit">
        <div class="item-page__text-field mb-7">
          <small>Ссылка на счёт</small>
          <div class="d-flex align-center">
            <span>{{ bill.url }}</span>
          </div>
        </div>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="number"
            @change="editBillPage"
            :readonly="!hasRight('bills.manage')"
            hide-details
        >
          <template v-slot:label>
            Номер счёта
          </template>
        </v-text-field>
        <div class="pointer" @click="openClientPage(bill.client.id)">
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="bill.client.name"
              disabled
              v-if="bill.client"
              hide-details
          >
            <template v-slot:label>
              Клиент
            </template>
          </v-text-field>
        </div>
        <div class="pointer" @click="openDealPage(bill.deal.id)">
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="bill.deal.name"
              disabled
              v-if="bill.deal"
              hide-details
          >
            <template v-slot:label>
              Сделка
            </template>
          </v-text-field>
        </div>
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateFormated"
                v-bind="attrs"
                v-on="on"
                readonly
                label="Дата выставления счета"
                color="#44D370"
                class="evi-text-field mb-7"
                hide-details
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              @input="menu = false"
              color="#44D370"
              @change="editBillPage"
              first-day-of-week="1"
              :readonly="!hasRight('bills.manage')"
          ></v-date-picker>
        </v-menu>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="reason"
            :readonly="!hasRight('bills.manage')"
            @change="editBillPage"
            hide-details
        >
          <template v-slot:label>
            Основание для счета (договор)
          </template>
        </v-text-field>
        <div class="item-page__text-field mb-11" v-if="bill.deal && bill.deal.sum">
          <small>Сумма сделки</small>
          <div class="d-flex align-center">
            <span>{{ bill.deal.sum | VMask(currencyMask) }}</span>
          </div>
        </div>
        <v-select
            class="evi-select mb-7"
            color="#44D370"
            v-model="nds"
            :items="ndsItems"
            item-text="name"
            item-value="id"
            label="НДС"
            item-color="green"
            return-object
            :readonly="!hasRight('bills.manage')"
            @change="editBillPage"
            hide-details
        >
          <template v-slot:append>
              <span class="evi-select__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
          </template>
        </v-select>
      </div>
    </div>
    <v-dialog
        v-model="removeModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление: Счёт №{{bill.number}}
        </v-card-title>
        <v-card-text >Вы действительно хотите удалить счёт?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeBill"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {hasRight} from '@/utils/access/hasRight';
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "BillPage",
  mixins: [OpenPagesMixin],
  data: () => ({
    number: '',
    removeModal: false,
    currencyMask,
    date: '',
    menu: false,
    sum: '',
    nds: 0,
    reason: '',
  }),
  computed: {
    ...mapState("bills", ['bill', 'ndsItems']),
    ...mapState("common", ["loading"]),
    priceNum () {
      if (this.price) {
        let priceArray  = this.price.split(" ");

        let sum = '';

        for(let i = 0; i < priceArray.length; i++){
          sum += priceArray[i];
        }

        return parseFloat(sum);
      }
      else {
        return 0;
      }
    },
    dateFormated() {
      return this.$moment(this.date).format('DD.MM.YYYY');
    },
  },
  methods: {
    ...mapActions({
      getBill: 'bills/getBill',
      editBill: 'bills/editBill',
      deleteBill: 'bills/deleteBill',
    }),
    hasRight: hasRight,
    openUrlNewTab () {
      window.open(this.bill.url, '_blank');
    },
    copyUrl () {
      let copyTextarea = document.createElement("textarea");
      copyTextarea.style.position = "fixed";
      copyTextarea.style.opacity = "0";
      copyTextarea.textContent = this.bill.url;

      document.body.appendChild(copyTextarea);
      copyTextarea.select();
      document.execCommand("copy");
      document.body.removeChild(copyTextarea);
    },
    getData() {
      let id = this.$route.params.id;
      this.getBill(id);
    },
    editBillPage () {
      this.editBill({
        id: this.bill.id,
        number: this.number,
        client_id: this.bill.client.id,
        deal_id: this.bill.deal.id,
        bill_date: this.date,
        tax: this.nds.id,
        reason: this.reason,
      });
    },
    removeBill () {
      this.deleteBill(this.bill).then(() => {
        this.removeModal = false;
        this.$router.push({path: '/bills'});
      });
    },
    returnNds (id) {
      return this.ndsItems.find((n) => n.id === id);
    },
  },
  watch: {
    bill (val) {
      this.number = val.number;
      this.date = this.$moment(val.bill_date).format('YYYY-MM-DD');
      this.reason = val.reason;
      this.sum = val.deal ? val.deal.sum : '';
      this.nds = this.returnNds(val.tax);
    },
    '$route.params.id': {
      handler: function() {
        this.getData();
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
